<template>
  <div class="padding24 color_white">
    <div v-if="isShow" id="global_constants">
      <a-table
        :columns="TaskColumns"
        :data-source="tabledData"
        :pagination="false"
        :loading="tableLoading"
        :row-key="record => record.id"
      >
        <span slot="number" slot-scope="number, record, index">
          <span>{{ index + 1 }}</span>
        </span>
        <span slot="state" slot-scope="state">{{ state === 1 ? '启用' : '禁用' }}</span>
        <span slot="actions" slot-scope="record">
          <!-- <router-link v-if="operation.edit" v-show="record.id === 10 || record.id === 24" :to="`/marketingCenter/taskCenter/edit/${record.id}`">编辑</router-link> -->
          <a-popconfirm
            v-if="operation.disabled"
            style="margin-left: 10px"
            :title="`确定${record.state === 1 ? '禁用' : '启用'}该任务`"
            @confirm="isDisabledClick(record)"
          >
            <a>{{record.state === 1 ? '禁用' : '启用'}}</a>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { codeFn } from '@/utils/tools'
import { TaskColumns } from '../constant'
import { getShopTaskList, removedShopTask } from '@/request/api/marketingCenter'
export default {
  data() {
    return {
      operation: {
        edit: false,
        disabled: false
      },
      tableLoading: false,
      TaskColumns,
      tabledData: [],
    }
  },
  computed: {
    isShow() {
      return this.$route.meta.isShow
    }
  },
  watch: {
    isShow(val) {
      if (val) this.fetchData()
    }
  },
  created() {
    this.operation.edit = !!codeFn('editTask')
    this.operation.disabled = !!codeFn('disableTask')
    this.fetchData()
  },
  methods: {
    // 编辑
    async editConstant() {
      this.$message.success("修改成功");
      this.fetchData()
    },
    async isDisabledClick(record) {
      const params = {
        id: record.id,
        state: record.state === 1 ? 2 : 1
      }
      const { code } = await removedShopTask(params)
      if (code !== 200) return
      this.$message.success('设置成功')
      this.fetchData()
    },
    // 获取列表
    async fetchData() {
      this.tableLoading = true
      const { data, code } = await getShopTaskList()
      this.tableLoading = false
      if (code !== 200) return
      this.tabledData = data
    }
  }
}
</script>
 
<style lang="less" scoped>
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>