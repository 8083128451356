export const TaskColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    key: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '任务名称',
    dataIndex: 'taskName',
    key: 'taskName',
  },
  {
    title: '任务分类',
    dataIndex: 'taskType',
    key: 'taskType',
  },
  {
    title: '任务周期',
    dataIndex: 'taskCycle',
    key: 'taskCycle',
  },
  {
    title: '奖励周期',
    dataIndex: 'rewardCycle',
    key: 'rewardCycle',
  },
  {
    title: '任务状态',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '操作',
    key: 'actions',
    width: '10%',
    scopedSlots: { customRender: 'actions' },
  },
]